export const routes = [
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/app/frontend/src/pages/[...all].vue'),
    /* no children */
  },
  {
    path: '/affiliate',
    name: '/affiliate',
    component: () => import('/app/frontend/src/pages/affiliate.vue'),
    /* no children */
  },
  {
    path: '/campaigns',
    /* internal name: '/campaigns' */
    /* no component */
    children: [
      {
        path: '',
        name: '/campaigns/',
        component: () => import('/app/frontend/src/pages/campaigns/index.vue'),
        /* no children */
      },
      {
        path: ':campaign',
        name: '/campaigns/[campaign]',
        component: () => import('/app/frontend/src/pages/campaigns/[campaign].vue'),
        children: [
          {
            path: '',
            name: '/campaigns/[campaign]/',
            component: () => import('/app/frontend/src/pages/campaigns/[campaign]/index.vue'),
            /* no children */
          },
          {
            path: 'analytics',
            name: '/campaigns/[campaign]/analytics',
            component: () => import('/app/frontend/src/pages/campaigns/[campaign]/analytics.vue'),
            /* no children */
          },
          {
            path: 'engage',
            name: '/campaigns/[campaign]/engage',
            component: () => import('/app/frontend/src/pages/campaigns/[campaign]/engage.vue'),
            /* no children */
          },
          {
            path: 'export',
            name: '/campaigns/[campaign]/export',
            component: () => import('/app/frontend/src/pages/campaigns/[campaign]/export.vue'),
            /* no children */
          },
          {
            path: 'import',
            /* internal name: '/campaigns/[campaign]/import' */
            /* no component */
            children: [
              {
                path: '',
                name: '/campaigns/[campaign]/import/',
                component: () => import('/app/frontend/src/pages/campaigns/[campaign]/import/index.vue'),
                /* no children */
              },
              {
                path: 'clay',
                name: '/campaigns/[campaign]/import/clay',
                component: () => import('/app/frontend/src/pages/campaigns/[campaign]/import/clay.vue'),
                /* no children */
              },
              {
                path: 'csv',
                name: '/campaigns/[campaign]/import/csv',
                component: () => import('/app/frontend/src/pages/campaigns/[campaign]/import/csv.vue'),
                /* no children */
              },
              {
                path: 'rb2b',
                name: '/campaigns/[campaign]/import/rb2b',
                component: () => import('/app/frontend/src/pages/campaigns/[campaign]/import/rb2b.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'send',
            name: '/campaigns/[campaign]/send',
            component: () => import('/app/frontend/src/pages/campaigns/[campaign]/send.vue'),
            /* no children */
          },
          {
            path: 'settings',
            name: '/campaigns/[campaign]/settings',
            component: () => import('/app/frontend/src/pages/campaigns/[campaign]/settings.vue'),
            /* no children */
          },
          {
            path: 'videos',
            name: '/campaigns/[campaign]/videos',
            component: () => import('/app/frontend/src/pages/campaigns/[campaign]/videos.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'new',
        name: '/campaigns/new',
        component: () => import('/app/frontend/src/pages/campaigns/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/creator',
    /* internal name: '/creator' */
    /* no component */
    children: [
      {
        path: 'campaign',
        /* internal name: '/creator/campaign' */
        /* no component */
        children: [
          {
            path: ':campaign',
            name: '/creator/campaign/[campaign]',
            component: () => import('/app/frontend/src/pages/creator/campaign/[campaign].vue'),
            children: [
              {
                path: '',
                name: '/creator/campaign/[campaign]/',
                component: () => import('/app/frontend/src/pages/creator/campaign/[campaign]/index.vue'),
                /* no children */
              },
              {
                path: 'explanation',
                name: '/creator/campaign/[campaign]/explanation',
                component: () => import('/app/frontend/src/pages/creator/campaign/[campaign]/explanation.vue'),
                /* no children */
              },
              {
                path: 'record',
                name: '/creator/campaign/[campaign]/record',
                component: () => import('/app/frontend/src/pages/creator/campaign/[campaign]/record.vue'),
                /* no children */
              },
              {
                path: 'script',
                name: '/creator/campaign/[campaign]/script',
                component: () => import('/app/frontend/src/pages/creator/campaign/[campaign]/script.vue'),
                /* no children */
              },
              {
                path: 'templates',
                name: '/creator/campaign/[campaign]/templates',
                component: () => import('/app/frontend/src/pages/creator/campaign/[campaign]/templates.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/demo',
    /* internal name: '/demo' */
    /* no component */
    children: [
      {
        path: '',
        name: '/demo/',
        component: () => import('/app/frontend/src/pages/demo/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/editor',
    /* internal name: '/editor' */
    /* no component */
    children: [
      {
        path: 'campaign',
        /* internal name: '/editor/campaign' */
        /* no component */
        children: [
          {
            path: ':campaign',
            name: '/editor/campaign/[campaign]',
            component: () => import('/app/frontend/src/pages/editor/campaign/[campaign].vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/onboarding',
    /* internal name: '/onboarding' */
    /* no component */
    children: [
      {
        path: 'call',
        name: '/onboarding/call',
        component: () => import('/app/frontend/src/pages/onboarding/call.vue'),
        /* no children */
      },
      {
        path: 'job-title',
        name: '/onboarding/job-title',
        component: () => import('/app/frontend/src/pages/onboarding/job-title.vue'),
        /* no children */
      },
      {
        path: 'referral-source',
        name: '/onboarding/referral-source',
        component: () => import('/app/frontend/src/pages/onboarding/referral-source.vue'),
        /* no children */
      },
      {
        path: 'register',
        name: '/onboarding/register',
        component: () => import('/app/frontend/src/pages/onboarding/register.vue'),
        /* no children */
      },
      {
        path: 'sending-tool',
        name: '/onboarding/sending-tool',
        component: () => import('/app/frontend/src/pages/onboarding/sending-tool.vue'),
        /* no children */
      },
      {
        path: 'trial',
        name: '/onboarding/trial',
        component: () => import('/app/frontend/src/pages/onboarding/trial.vue'),
        /* no children */
      },
      {
        path: 'website',
        name: '/onboarding/website',
        component: () => import('/app/frontend/src/pages/onboarding/website.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/org',
    /* internal name: '/org' */
    /* no component */
    children: [
      {
        path: '',
        name: '/org/',
        component: () => import('/app/frontend/src/pages/org/index.vue'),
        /* no children */
      },
      {
        path: 'domains',
        name: '/org/domains',
        component: () => import('/app/frontend/src/pages/org/domains.vue'),
        /* no children */
      },
      {
        path: 'thank-you',
        name: '/org/thank-you',
        component: () => import('/app/frontend/src/pages/org/thank-you.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/profile',
    name: '/profile',
    component: () => import('/app/frontend/src/pages/profile.vue'),
    /* no children */
  },
  {
    path: '/sign-up',
    name: '/sign-up',
    component: () => import('/app/frontend/src/pages/sign-up.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

