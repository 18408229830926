/* eslint-disable no-console */
import "./styles/main.css"
import type { ComponentPublicInstance } from "vue"
import { createApp } from "vue"
import type { RouteRecordRaw } from "vue-router"
import { createRouter, createWebHistory } from "vue-router"
import { createHead } from "@vueuse/head"
import * as Sentry from "@sentry/browser"
import { datadogRum } from "@datadog/browser-rum"
import Popper from "vue3-popper"
import "virtual:vite-plugin-sentry/sentry-config"

import { routes as pageRoutes } from "vue-router/auto-routes"
import App from "./App.vue"
import { initAuth } from "./utils/auth"
import useLiveChat from "./utils/live-chat"
import { isProd, isStaging } from "./utils/environment"
import api from "./utils/api"
import amplitude from "amplitude-js"
import { until } from "@vueuse/core"
import FloatingVue from "floating-vue"
import "floating-vue/dist/style.css"
import { initMedian } from "./utils/median"
import ServiceBell from "@servicebell/widget"

const app = createApp(App)
app.component("Popper", Popper)
app.use(FloatingVue)

const routes: RouteRecordRaw[] = [
    ...pageRoutes,
    { path: "/", redirect: "/campaigns" },
]

export const history = createWebHistory()
export const router = createRouter({ history, routes })

router.afterEach(() => {
    const url = new URL(window.location.href)

    window.analytics?.page()
    window.__ls?.("newPageView")

    amplitude.getInstance().logEvent("Viewed", {
        path: url.pathname,
        search: url.search,
        url: url.href,
        referrer: document.referrer,
    })

    // refersion parameters
    url.searchParams.forEach((value, key) => {
        if (key.startsWith("rfsn_")) {
            window.localStorage.setItem(key, value)
        }
    })

    // check cookie permissions before sending data to pixels
    const { purposes = {} } = window._iub?.cs?.api?.getPreferences() ?? {}
    if (purposes?.["5"]) {
        window.fbq?.("track", "PageView")
        window.gtag?.("event", "page_view", {
            page_title: document.title,
            page_location: window.location,
        })
    }
})
app.use(router)

// if we've been unable to fetch a new route, it's likely there's been a release and we should just navigate there by refreshing
router.onError((error, to) => {
    console.warn(
        "Navigation onError - assuming a release has occurred and reloading.",
        error,
    )
    const currentPath =
        window.location.pathname + window.location.search + window.location.hash

    if (to.fullPath !== currentPath) {
        window.location.href = to.fullPath
    } else {
        // prevent a redirect loop in case we've miscategorised a genuine error
        const url = new URL(window.location.href)
        const noRedirect = url.searchParams.get("noredirect") === "true"
        if (noRedirect) {
            console.warn("Redirect prevented due to noredirect flag")
            console.error("Uncaught Navigation Error", error)
            return
        }

        const targetPath = to.fullPath
        const separator = targetPath.includes("?") ? "&" : "?"
        const newHref = `${targetPath}${separator}noredirect=true`

        if (newHref !== currentPath) {
            window.location.href = newHref
        } else {
            window.location.reload()
        }
    }
})

const head = createHead()
app.use(head)

async function main() {
    if (isProd() || isStaging()) {
        const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist
        const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release

        Sentry.init({
            dsn: "https://d923af8bf2b44c4891dba24e5f1a1453@o553095.ingest.sentry.io/5839012",
            environment: isProd()
                ? "production"
                : isStaging()
                  ? "staging"
                  : "dev",
            dist,
            release,
            ignoreErrors: [
                "Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type",
            ],
            denyUrls: [
                /^chrome-extension:\//,
                /safari-web-extension/,
                /cdn\.servicebell\.com/,
                /cdn\.segment\.com/,
            ],
            integrations: [
                Sentry.captureConsoleIntegration({
                    levels: ["error", "warn"],
                }),
            ],

            // livesession integrtion
            beforeSend: function beforeSend(event) {
                let createdSessionURL = ""

                window.__ls?.(
                    "getSessionURL",
                    (url: string, isNewSession: boolean) => {
                        createdSessionURL = url
                    },
                )

                const wait = function wait(ms: number) {
                    return new Promise((r, j) => {
                        return setTimeout(r, ms)
                    })
                }

                return wait(3000).then(() => {
                    event.extra!.sessionURL = createdSessionURL
                    return event
                })
            },
        })

        Sentry.getCurrentScope().setExtra("sessionURL", "")

        datadogRum.init({
            applicationId: "3fd124bd-9c5e-46ff-895a-6cc51e0222f8",
            clientToken: "pub436197d657f0f89ba1eab86ca5a098e9",
            site: "datadoghq.eu",
            service: "pitchlane-dashboard",
            env: isProd() ? "production" : "staging",
            version: release,
            traceSampleRate: 100,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: "mask-user-input",
            excludedActivityUrls: [
                /^chrome-extension:\//,
                /safari-web-extension/,
                /cdn\.servicebell\.com/,
                /cdn\.segment\.com/,
            ],
        })
        datadogRum.startSessionReplayRecording()
    }

    if (isProd()) {
        ServiceBell?.("init", "b9614ae46dcf4d09b73a10c7c1964792")
        initMedian()
    }

    // analytics
    until(() => window.analytics !== undefined)
        .toBeTruthy()
        .then(() => {
            const SEGMENT_WRITE_KEY = isProd()
                ? "a0qPSNtsZxWudyFpa2oD7t5lPgr7B7HC" // production
                : "NUw9I4Cc7pmUkxmHHpwJtIJawMkB0ZlI" // staging

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            window.analytics!.load(SEGMENT_WRITE_KEY)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            window.analytics!.page()
        })

    app.config.errorHandler = (
        error: any,
        instance: ComponentPublicInstance | null,
        info: string,
    ) => {
        Sentry.setContext("appError", { instance, info })
        console.error("App Error", error)
    }

    app.config.warnHandler = (
        msg: string,
        instance: ComponentPublicInstance | null,
        trace: string,
    ) => {
        Sentry.setContext("appWarning", { instance, trace })
        console.warn("App Warning", msg, trace)
    }

    await initAuth(api)
    app.mount("#app")

    useLiveChat()
}

main()
